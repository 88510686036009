/*
 *   File : reject-request.js
 *   Author : https://evoqins.com
 *   Description : Registration request rejection modal
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

//Custom component
import { CustomSearchSuggestion, TextArea } from '../FormElements';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { APIService } from '../../Service';
import { Icon } from '../Icon';

const RejectRequestModal = (props) => {

    const [message, setMessage] = useState("");
    const [selected, setSelected] = useState(null);
    const [selectedError, setSelectedError] = useState("");
    const [rejectStatus, setRejectStatus] = useState(false);

    useEffect(() => {
        setSelectedError("");
    }, [selected]);

    // handle select search value 
    const _handleSelect = (value) => {
        setSelected(value);
    }

    //handle modal close
    const _handleCloseRejectModal = () => {
        var button = document.getElementById("request-reject-modal-close");
        button.click();

        setSelectedError("");
        setMessage("");
        setRejectStatus(false);
        setSelected(null);
        props.handleClose();
    };

    //handle modal close
    const _handleSuccess = () => {
        var button = document.getElementById("request-reject-modal-close");
        button.click();

        setSelectedError("");
        setMessage("");
        setRejectStatus(false);
        setSelected(null);
        props.handleClose();
        props.success();
    };

    // handle reject confirm
    const _handleReject = (type) => {
        let is_valid_form = true;

        if (selected === null) {
            setSelectedError("Reason is required");
            is_valid_form = false;
        }

        if (is_valid_form === true) {
            _rejectRequest(type);
        }
    }

    // API - get rejection reason list 
    const _rejectRequest = (type) => {
        let is_post = true;
        let url = 'CA/registration/manage';
        let data = {
            "customer_id": props.customer_id,
            "is_approve": type,
            "reason_id": selected.id,
            "reason": message
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setRejectStatus(true);
            } else {
                toast.dismiss();
                toast.error(response.data.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    return (
        <div className="modal fade" id="request-reject-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="requestRejectModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered e-border-radius-24">
                <div className="modal-content e-border-radius-8">
                    <button type="button"
                        id='request-reject-modal-close'
                        className="btn-close shadow-none d-none"
                        data-bs-dismiss="modal" aria-label="Close"></button>
                    {rejectStatus !== true ?
                        <>
                            <div className="modal-header px-4 pt-4 pb-0 border-0">
                                {/* autocomplete search input */}
                                <CustomSearchSuggestion placeholder="Search and add reason for rejection"
                                    selected={selected}
                                    error={selectedError}
                                    options={props.reason}
                                    handleSelect={_handleSelect} />
                            </div>
                            <div className="modal-body px-4 pb-4 pt-0">

                                <TextArea label="Additional information"
                                    placeholder="Enter your text"
                                    type="text"
                                    rows="3"
                                    value={message}
                                    className="w-100 px-3 padding-12px-tb"
                                    handleChange={(e) => setMessage(e)} />

                                <div className='row pt-2'>
                                    <div className='col-6 pe-1'>
                                        <SecondaryButton name="Cancel"
                                            className="e-border-radius-8 w-100"
                                            onPress={_handleCloseRejectModal} />
                                    </div>
                                    <div className='col-6 ps-1'>
                                        <PrimaryButton name="Reject"
                                            className="e-border-radius-8 w-100"
                                            onPress={() => _handleReject(false)} />
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="modal-header px-4 pt-4 pb-2 border-0 align-items-start">
                                <img src={require("../../Assets/Image/CountryAdmin/success-icon.svg").default}
                                    draggable={false}
                                    alt='icon' />
                                <Icon icon="close-icon"
                                    className='cursor-pointer'
                                    size="24px"
                                    onPress={_handleSuccess} />
                                <button type="button" className="btn-close shadow-none d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body px-4 pb-4 pt-0">
                                <h6 className='mb-2 e-primary-font-playfair e-font-18 e-text-raisin-black e-font-weight-500 line-height-28px'>
                                    Rejected successfully
                                </h6>
                                <p className='mb-0 e-text-davys-gray e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                    Successfully rejected the application.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                </p>
                                <div className='pt-4 mt-1'>
                                    <PrimaryButton name="Ok"
                                        className="w-100"
                                        onPress={_handleSuccess} />
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default memo(RejectRequestModal);