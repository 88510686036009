/*   File : manage-assign.js
 *   Author : https://evoqins.com
 *   Description : Manage assign employees modal
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

//Custom components
import { Icon } from '../Icon';
import { CustomSelectBox } from '../FormElements';
import { PrimaryButton } from '../Buttons';
import { Loader } from '../Others';
import { APIService } from '../../Service';

const ManageAssignModal = (props) => {

    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [selectedAdminError, setSelectedAdminError] = useState("");

    const [assignLoader, setAssignLoader] = useState(false);

    useEffect(() => {
        setSelectedAdminError("");
    }, [selectedAdmin]);

    // handle close modal
    const _handleCloseModal = () => {
        var button = document.getElementById("manage-assign-modal-close");
        button.click();
        props.handleClose();

        setSelectedAdminError("")
        setSelectedAdmin(null);
    };

    // handle success modal
    const _handleSuccess = () => {
        var button = document.getElementById("manage-assign-modal-close");
        button.click();
        props.handleSuccess();

        setSelectedAdminError("")
        setSelectedAdmin(null);
    };


    // handle submit 
    const _handleSubmit = () => {
        if (selectedAdmin === null) {
            setSelectedAdminError("Select a country admin");
            return;
        }
        setAssignLoader(true);
        _assignCountryAdmin();
    };

    // API - Assign to the country admin
    const _assignCountryAdmin = () => {
        let is_post = true;
        let url = 'CA/sales-specialist/assign-country-admin';
        let data = {
            "country_admin_id": selectedAdmin.value,
            "customer_ids": props.customerIds
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                _handleSuccess();
                toast.dismiss();
                toast.success("Assigned successfully", {
                    type: "success",
                    className: "e-toast",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setAssignLoader(false);
        });
    };

    return (
        <div className="modal fade"
            id="manage-assign-modal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="manageAssignModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable e-border-radius-24">
                <div className="modal-content e-border-radius-12 overflow-hidden">
                    <img src={require("../../Assets/Image/CountryAdmin/close-icon.svg").default}
                        draggable={false}
                        id='manage-assign-modal-close'
                        className='d-none '
                        alt='close'
                        data-bs-dismiss="modal" />
                    {props.loading ?
                        <Loader className="h-30vh py-4 my-0 justify-content-center d-flex align-items-center " /> //loader
                        :
                        <>
                            <div className="modal-header align-items-start flex-column px-4 pt-4 border-0">
                                <div className='d-flex justify-content-between w-100 mb-2'>
                                    <h4 className="mb-0 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px">
                                        Assign country admin
                                    </h4>
                                    <Icon icon="close-icon"
                                        className='cursor-pointer'
                                        size="24px"
                                        onPress={_handleCloseModal} />
                                </div>
                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </p>
                            </div>

                            <div className='modal-body py-0 px-4'>
                                <CustomSelectBox label="Country admin"
                                    placeholder="select country admin"
                                    value={selectedAdmin}
                                    options={props.options}
                                    error={selectedAdminError}
                                    className="e-modal-select"
                                    menuPortalTarget={document.body}
                                    isSearchable={true}
                                    selectChange={(id) => { setSelectedAdmin(id); }} />
                            </div>

                            <div className='modal-footer px-4 py-4 border-0'>
                                <PrimaryButton name="Submit"
                                    status={assignLoader}
                                    onPress={_handleSubmit} />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div >
    )
}

export default ManageAssignModal;
