/*   File : manage-competition.js
 *   Author : https://evoqins.com
 *   Description : Manage competition component
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';

//Custom component
import { CustomCheckbox, CustomDatePicker, CustomFileUpload, CustomSelectBox, CustomTextInput, TextArea } from '../FormElements';
import { PrimaryButton, SecondaryButton, TextButton } from '../Buttons';
import { _getCountryList } from '../../Helper/api';
import { Icon } from '../Icon';
import { Loader, OverLay } from '../Others';
import { APIService, AWSService } from '../../Service';

// style 
import styles from "../../Style/Component/modal.module.scss";

// Custom data

const TABS = [
    {
        id: 1,
        label: "Single product competition"
    },
    {
        id: 2,
        label: "Multiple product competition"
    },
];


const ManageCompetitionModal = React.memo((props) => {

    const [activeTabID, setActiveTabID] = useState(1);

    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState("");

    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");

    const [rewards, setRewards] = useState("");
    const [rewardsError, setRewardsError] = useState("");

    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [startDateError, setStartDateError] = useState("");
    const [startDateToggle, setStartDateToggle] = useState(false);

    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [endDateError, setEndDateError] = useState("");
    const [endDateToggle, setEndDateToggle] = useState(false);

    const [salesTarget, setSalesTarget] = useState("");
    const [salesTargetError, setSalesTargetError] = useState("");

    const [targetProduct, setTargetProduct] = useState(null);
    const [targetProductError, setTargetProductError] = useState("");

    const [image, setImage] = useState(null);
    const [imageError, setImageError] = useState("");
    const [imageUploadLoader, setImageUploadLoader] = useState(true);

    const [rules, setRules] = useState([""]);
    const [rulesError, setRulesError] = useState([""]);

    const [createLoader, setCreateLoader] = useState(true);


    // Multi product competition states
    const [isSaleTargetChecked, setIsSaleTargetChecked] = useState(true);

    const [multiProductTitle, setMultiProductTitle] = useState("");
    const [multiProductTitleError, setMultiProductTitleError] = useState("");

    const [multiProductDescription, setMultiProductDescription] = useState("");
    const [multiProductDescriptionError, setMultiProductDescriptionError] = useState("");

    const [multiProductReward, setMultiProductReward] = useState("");
    const [multiProductRewardError, setMultiProductRewardError] = useState("");

    const [multiProductStartDate, setMultiProductStartDate] = useState(null);
    const [multiProductStartDateError, setMultiProductStartDateError] = useState("");

    const [multiProductEndDate, setMultiProductEndDate] = useState(null);
    const [multiProductEndDateError, setMultiProductEndDateError] = useState("");

    const [multiProductRules, setMultiProductRules] = useState([""]);
    const [multiProductRulesErrors, setMultiProductRulesErrors] = useState([""]);

    const [multiProductImage, setMultiProductImage] = useState(null);
    const [multiProductImageError, setMultiProductImageError] = useState(null);

    const [multiTargetProducts, setMultiTargetProducts] = useState([null]);
    const [multiTargetProductsError, setMultiTargetProductsError] = useState([]);

    const [multiProductsSalesTarget, setMultiProductsSalesTarget] = useState([""]);
    const [multiProductsSalesTargetError, setMultiProductsSalesTargetError] = useState("");

    const [totalSalesTarget, setTotalSalesTarget] = useState("");
    const [totalSalesTargetError, setTotalSalesTargetError] = useState("");
    const [errorUpdate, setErrorUpdate] = useState(false);

    useEffect(() => {
        setCreateLoader(false);
        setImageUploadLoader(false);
    }, []);

    useEffect(() => {
        if (props.edit === true && Object.keys(props.detailData).length !== 0) {
            setTitle(props.detailData.title);
            setDescription(props.detailData.description);
            setRewards(props.detailData.points);

            setSelectedStartDate(props.detailData.start_date);
            setSelectedEndDate(props.detailData.end_date);
            setRules(props.detailData.rules);
            setImage(props.detailData.image_url);

            if (props.detailData.target.length !== 0) {
                setSalesTarget(props.detailData.target[0].sales_target);
                setTargetProduct({ value: props.detailData.target[0].target_product_id, label: props.detailData.target[0].target_product });
            }

            if (props.detailData.type !== null && props.detailData.type === 2) {
                setActiveTabID(2)

                const multi_target_products = props.detailData.target.map(target => ({
                    competition_target_id: target.competition_target_id,
                    sales_target: target.sales_target,
                    value: target.target_product_id,
                    label: target.target_product

                }));

                const sales_target = props.detailData.target.map(target => target.sales_target);

                setMultiTargetProducts(multi_target_products);
                setMultiProductsSalesTarget(sales_target);

                setMultiProductTitle(props.detailData.title);
                setMultiProductDescription(props.detailData.description);
                setMultiProductReward(props.detailData.points);
                setMultiProductStartDate(props.detailData.start_date);
                setMultiProductEndDate(props.detailData.end_date);
                setMultiProductRules(props.detailData.rules);
                setMultiProductImage(props.detailData.image_url);
            }
        }
    }, [props.detailData]);

    useEffect(() => {
        if (startDateToggle === true) {
            const start_date = new Modal(document.getElementById('start-date-modal'));
            start_date.show();
        }
    }, [startDateToggle]);

    useEffect(() => {
        if (endDateToggle === true) {
            const end_date = new Modal(document.getElementById('end-date-modal'));
            end_date.show();
        }
    }, [endDateToggle]);

    useEffect(() => {
        setTitleError("");
    }, [title]);

    useEffect(() => {
        setDescriptionError("");
    }, [description]);

    useEffect(() => {
        setRewardsError("");
    }, [rewards]);

    useEffect(() => {
        setTargetProductError("");
    }, [targetProduct]);

    useEffect(() => {
        setSalesTargetError("");
    }, [salesTarget]);

    useEffect(() => {
        setStartDateError("");
    }, [selectedStartDate]);

    useEffect(() => {
        setEndDateError("");
    }, [selectedEndDate]);

    useEffect(() => {
        setImageError("");
    }, [image]);


    // handle Title Change
    const _handleChangeTitle = (value) => {
        if (activeTabID == 1) {
            setTitle(value);
        } else {
            setMultiProductTitle(value);
            setMultiProductTitleError("");
        }
    };

    // handle Description Change
    const _handleDescriptionChange = (value) => {
        if (activeTabID == 1) {
            setDescription(value);
        } else {
            setMultiProductDescription(value);
            setMultiProductDescriptionError("");
        }
    };

    // handle Reward point Change
    const _handleRewardPointChange = (value) => {
        if (activeTabID == 1) {
            if (value.length <= 6) {
                setRewards(value);
            }
        } else {
            if (value.length <= 6) {
                setMultiProductReward(value);
                setMultiProductRewardError("");
            }
        }

    };

    // handle active tab change
    const _handleTabChange = (tab_id) => {
        setActiveTabID(tab_id);
        if (tab_id == 1) {
            setIsSaleTargetChecked(true);
        }
    };

    // handle multi product select 
    const _handleMultiProductSelect = (selected, i) => {
        let product_list = [...multiTargetProducts];
        product_list[i] = selected;
        setMultiTargetProducts([...product_list]);

        // Updating errors
        let errors = [...multiTargetProductsError];
        errors[i] = "";
        setMultiTargetProductsError([...errors]);
    };

    // handle add more product
    const _handleAddMoreProduct = () => {
        setMultiTargetProducts([...multiTargetProducts, null]);
        setMultiProductsSalesTarget([...multiProductsSalesTarget, ""]);
        setMultiTargetProductsError([...multiTargetProductsError, ""]);
        setMultiProductsSalesTargetError([...multiProductsSalesTargetError, ""]);

        // _handleUpdateTotalSalesTarget();
    };

    // handle Change Multi Product Sales Target
    const _handleChangeMultiProductSalesTarget = (value, i) => {
        let sales_target = [...multiProductsSalesTarget];
        sales_target[i] = value;
        setMultiProductsSalesTarget([...sales_target]);

        // _handleUpdateTotalSalesTarget();

        // Updating errors
        let errors = [...multiProductsSalesTargetError];
        errors[i] = "";
        setMultiProductsSalesTargetError([...errors]);
    };

    // handle remove product 
    const _handleRemoveProduct = (key) => {
        let product_list = [...multiTargetProducts];
        product_list = product_list.filter((_, index) => index !== key);
        setMultiTargetProducts(product_list);

        let sales_target_list = [...multiProductsSalesTarget];
        sales_target_list = sales_target_list.filter((_, index) => index !== key);
        setMultiProductsSalesTarget(sales_target_list);

        const updated_target_products_error = [...multiTargetProductsError];
        updated_target_products_error.splice(key, 1);
        setMultiTargetProductsError(updated_target_products_error);

        const updated_target_products_sales_error = [...multiProductsSalesTargetError];
        updated_target_products_sales_error.splice(key, 1);
        setMultiProductsSalesTargetError(updated_target_products_sales_error);

        // _handleUpdateTotalSalesTarget();

    };

    // const _handleTotalSalesTargetChange = (value) => {
    //     setTotalSalesTarget(value);
    //     setTotalSalesTargetError("");
    // };

    // const _handleUpdateTotalSalesTarget = () => {
    //     let sales_target = [...multiProductsSalesTarget];
    //     let total_sales_target = sales_target.reduce((a, b) => a + b);
    //     setTotalSalesTarget(total_sales_target);
    // };

    // handle adding a new rule
    const _handleAddMoreRule = () => {
        setRules([...rules, ""]);
        setRulesError([...rulesError, ""]);
    };

    // handle adding a new multiproduct rule
    const _handleAddMoreMultiProductRule = () => {
        setMultiProductRules([...multiProductRules, ""]);
        setMultiProductRulesErrors([...multiProductRulesErrors, ""]);
    };

    // handle change sale target set checkbox
    const _handleCheckSetSaleTargetChange = (checked_status) => {
        setIsSaleTargetChecked(checked_status);
    };

    //handle removing a rule
    const _handleRemoveRule = (index) => {
        const updated_rule = [...rules];
        updated_rule.splice(index, 1);
        setRules(updated_rule);

        const updated_error = [...rulesError];
        updated_error.splice(index, 1);
        setRulesError(updated_error);
    };

    //handle removing multi product rule
    const _handleRemoveMultiProductRule = (index) => {
        const updated_rule = [...multiProductRules];
        updated_rule.splice(index, 1);
        setMultiProductRules(updated_rule);

        const updated_error = [...multiProductRulesErrors];
        updated_error.splice(index, 1);
        setMultiProductRulesErrors(updated_error);
    };

    // handle change
    const _handleChangeRule = useMemo(() => (index, value) => {
        const updated_rule = [...rules];
        updated_rule[index] = value;
        setRules(updated_rule);

        setRulesError(prev => {
            const updated_errors = [...prev];
            updated_errors[index] = "";
            return updated_errors;
        });

    }, [rules]);

    // handle change
    const _handleChangeMultiProductRule = useMemo(() => (index, value) => {
        const updated_rule = [...multiProductRules];
        updated_rule[index] = value;
        setMultiProductRules(updated_rule);

        setMultiProductRulesErrors(prev => {
            const updated_errors = [...prev];
            updated_errors[index] = "";
            return updated_errors;
        });

    }, [multiProductRules]);

    // handle close modal
    const _handleCloseModal = () => {
        var button = document.getElementById("manage-competition-modal-close");
        button.click();
        setTitle("");
        setRewards("");
        setDescription("");
        setSalesTarget("")
        setTargetProduct(null);
        setSelectedStartDate(null);
        setSelectedEndDate(null);
        setImage(null);
        setTitleError("");
        setRewardsError("");
        setDescriptionError("");
        setSalesTargetError("")
        setTargetProductError(null);
        setStartDateError(null);
        setEndDateError(null);
        setImageError(null);

        props.handleClose();
    };

    // start date toggle show an hide
    const _handleStartDateToggle = () => {
        setStartDateToggle(!startDateToggle);
    };

    // handle select start date
    const _handleSelectedStartDate = (day) => {

        if (activeTabID == 1) {
            setSelectedStartDate(day);
        } else {
            setMultiProductStartDate(day);
            setMultiProductStartDateError("");
        };

        setStartDateToggle(false);
        _handleCloseStartDateModal();
    };

    // handle close start date modal 
    const _handleCloseStartDateModal = () => {
        var button = document.getElementById("start-date-modal-close");
        button.click();
        setStartDateToggle(false);
    };

    // end date toggle show an hide
    const _handleEndDateToggle = () => {
        setEndDateToggle(!endDateToggle);
    };

    // handle select end date
    const _handleSelectedEndDate = (day) => {

        if (activeTabID == 1) {
            setSelectedEndDate(day);
        } else {
            setMultiProductEndDate(day);
            setMultiProductEndDateError("");
        }

        setEndDateToggle(false);
        _handleCloseEndDateModal();
    };

    // handle close end date modal 
    const _handleCloseEndDateModal = () => {
        var button = document.getElementById("end-date-modal-close");
        button.click();
        setEndDateToggle(false);
    };

    // handle upload image
    const _uploadedImage = (url) => {
        setImageUploadLoader(true);
        _getPresignedUrl(url);
    };

    // handle Image change 
    const _handleChangeImage = () => {
        if (activeTabID == 1) {
            setImage(null);
        } else {
            setMultiProductImage(null)
        }
    };

    // handle focus error
    const _focusError = (error_element_id) => {

        // if (errorRef.current[field]) {
        //     errorRef.current[field].scrollIntoView({ behavior: 'smooth', block: 'start' });
        // }

        if (document.getElementById(error_element_id)) {
            document.getElementById(error_element_id).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }

    };


    // handleScroll
    const _handleScroll = (element_id) => {

        if (document.getElementById(element_id)) {
            setTimeout(() => {
                document.getElementById(element_id).scrollIntoView({ behavior: 'smooth', block: "nearest", inline: "nearest" });
            }, 1);
        }
    };


    // handle add admin submit
    const _handleCreateCompetition = () => {

        let is_valid = true;
        let error_field = [];

        if (activeTabID == 1) {

            if (title.trim().length === 0) {
                setTitleError("Title is required");
                error_field.push('single-product-title');
                is_valid = false;
            }

            if (description.trim().length === 0) {
                setDescriptionError("Description is required");
                error_field.push('single-product-description');
                is_valid = false;
            }

            if (rewards.length == 0) {
                setRewardsError("Reward is required");
                error_field.push('single-product-reward');
                is_valid = false;
            }

            if (targetProduct === null) {
                setTargetProductError("Select target product");
                error_field.push('single-product-target-product');
                is_valid = false;
            }

            if (salesTarget.length === 0) {
                setSalesTargetError("Sales target is required");
                error_field.push('single-product-target-sales');
                is_valid = false;
            }

            if (selectedStartDate === null) {
                setStartDateError("Start date is required");
                error_field.push('single-product-startDate');
                is_valid = false;
            }

            if (selectedEndDate === null) {
                setEndDateError("End date is required");
                error_field.push('single-product-endDate');
                is_valid = false;
            }

            if (image === null) {
                setImageError("Image is required");
                error_field.push('single-product-image');
                is_valid = false;
            }

            // Validating rules
            const new_rules_error = rules.map((rule) => (rule.trim().length === 0 ? "Rule is required" : ""));
            setRulesError(new_rules_error);

            // Check if there are any errors
            if (new_rules_error.some((error) => error !== "")) {
                for (let i = 0; i < new_rules_error.length; i++) {
                    const error = new_rules_error[i];

                    if (error.trim().length !== 0) {
                        error_field.push(`single-product-rule-${i}`);
                    }
                }

                is_valid = false;
            }

        } else {

            if (multiProductTitle.trim().length === 0) {
                setMultiProductTitleError("Title is required")
                is_valid = false;
                error_field.push('multi-product-title');
            }

            if (multiProductDescription.trim().length === 0) {
                setMultiProductDescriptionError("Description is required")
                is_valid = false;
                error_field.push('multi-product-description');
            }

            if (multiProductReward.length == 0) {
                setMultiProductRewardError("Reward is required");
                is_valid = false;
                error_field.push('multi-product-reward');
            }


            if (multiProductStartDate === null) {
                setMultiProductStartDateError("Start date is required")
                is_valid = false;
                error_field.push('multi-product-startDate');
            }

            if (multiProductEndDate === null) {
                setMultiProductEndDateError("End date is required")
                is_valid = false;
                error_field.push('multi-product-endDate');
            }

            if (multiProductImage === null) {
                setMultiProductImageError("Image is required")
                is_valid = false;
                error_field.push('multi-product-image');
            }

            // multi-product-sales-target-${index}

            //==== Validating rules ====//
            const new_rules_error = multiProductRules.map((rule) => (rule.trim().length === 0 ? "Rule is required" : ""));
            setMultiProductRulesErrors(new_rules_error);

            // Check if there are any errors
            if (new_rules_error.some((error) => error !== "")) {
                for (let i = 0; i < new_rules_error.length; i++) {
                    const error = new_rules_error[i];
                    if (error.trim().length !== 0) {
                        error_field.push(`multi-product-rule-${i}`);
                    }
                }

                is_valid = false;
            }

            //===== Validating target product ====//
            const new_product_error = multiTargetProducts.map((product) => (product === null ? "Select target product" : ""));
            setMultiTargetProductsError(new_product_error);

            // Check if there are any errors
            if (new_product_error.some((error) => error !== "")) {
                for (let i = 0; i < new_product_error.length; i++) {
                    const error = new_product_error[i];
                    if (error.trim().length !== 0) {
                        error_field.push(`multi-product-${i}`);
                    }
                }

                is_valid = false;
            }


            //===== Validating sales target ====//

            if (isSaleTargetChecked === true) {

                const new_sales_target_errors = multiProductsSalesTarget.map((target) => (target.length === 0 ? "Sales target is required" : ""));
                setMultiProductsSalesTargetError(new_sales_target_errors);

                // Check if there are any errors
                if (new_sales_target_errors.some((error) => error !== "")) {
                    for (let i = 0; i < new_sales_target_errors.length; i++) {
                        const error = new_sales_target_errors[i];
                        if (error.trim().length !== 0) {
                            error_field.push(`multi-product-sales-target-${i}`);
                        }
                    }

                    is_valid = false;
                }

            } else {

                if (totalSalesTarget.length === 0) {
                    setTotalSalesTargetError("Sales target is required");
                    error_field.push('multi-product-total-sales-target');
                    is_valid = false;
                }
            }
        }

        setErrorUpdate(prev => !prev);

        if (is_valid === true) {
            setCreateLoader(true);
            if (props.edit === true) {
                _updateCompetition();
            } else {
                _createCompetition();
            }
        } else {
            if (error_field.length > 0) {
                _focusError(error_field[0]);
            }
        }

    };



    // =======================  //
    // ====== API CALLS ====== //

    // get presigned url
    async function _getPresignedUrl(file) {
        let is_post = true;
        let url = 'general/file-upload';
        let data = {
            "file": file.name,
            "is_public": true
        };
        try {
            let response = await APIService(is_post, url, data);
            if (response.status_code == 200) {
                let aws_fields = response.data.upload_info.fields;
                let aws_url = response.data.upload_info.url;

                try {
                    let aws_response = await AWSService(aws_fields, file, aws_url);
                    if (aws_response.status == 'ok') {
                        if (activeTabID == 1) {
                            setImage(response.data.view_info);
                        } else {
                            setMultiProductImage(response.data.view_info);
                            setMultiProductImageError("");
                        }

                    } else {
                        toast.dismiss();
                        toast.error(aws_response.message, {
                            type: "error",
                            className: "e-toast",
                        });
                    }
                } catch (error) { }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        } catch (err) { }
        setImageUploadLoader(false);
    };

    // API - Create competition
    const _createCompetition = () => {

        const product_data = multiTargetProducts.map((product, index) => {
            if (product !== null && multiProductsSalesTarget[index] !== "") {
                return {
                    product_id: product.value,
                    sales_target: multiProductsSalesTarget[index]
                };
            }
            return null;
        }).filter(item => item !== null);

        let is_post = true;
        let url = 'CA/competition/create';
        let data = {
            "type": activeTabID,
            "title": activeTabID === 1 ? title : multiProductTitle,
            "description": activeTabID === 1 ? description : multiProductDescription,
            "sales_target": activeTabID === 1 ? parseInt(salesTarget) : parseInt(multiProductsSalesTarget),
            "points": activeTabID === 1 ? parseInt(rewards) : parseInt(multiProductReward),
            "start_date": activeTabID === 1 ? format(selectedStartDate, 'yyyy-MM-dd') : format(multiProductStartDate, 'yyyy-MM-dd'),
            "end_date": activeTabID === 1 ? format(selectedEndDate, 'yyyy-MM-dd') : format(multiProductEndDate, 'yyyy-MM-dd'),
            "rules": activeTabID === 1 ? rules : multiProductRules,
            "image_url": activeTabID === 1 ? image : multiProductImage,
            "target": activeTabID === 1 ?
                {
                    product_id: targetProduct.value,
                    sales_target: parseInt(salesTarget)
                }
                :
                product_data
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                toast.dismiss();
                toast.success("Competition created successfully", {
                    type: "success",
                    className: "e-toast",
                });

                _handleCloseModal();
                props.success();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    // API - Update competition
    const _updateCompetition = () => {

        const product_data = multiTargetProducts.map((product, index) => {
            if (product !== null && multiProductsSalesTarget[index] !== "") {
                return {
                    product_id: product.value,
                    sales_target: multiProductsSalesTarget[index],
                    competition_target_id: product.competition_target_id
                };
            }
            return null;
        }).filter(item => item !== null);

        let is_post = true;
        let url = 'CA/competition/update';
        let data = {
            "type": activeTabID,
            "competition_id": props.detailData.competition_id,
            "title": activeTabID === 1 ? title : multiProductTitle,
            "description": activeTabID === 1 ? description : multiProductDescription,
            "sales_target": activeTabID === 1 ? parseInt(salesTarget) : parseInt(multiProductsSalesTarget),
            "points": activeTabID === 1 ? parseInt(rewards) : parseInt(multiProductReward),
            "start_date": activeTabID === 1 ? format(selectedStartDate, 'yyyy-MM-dd') : format(multiProductStartDate, 'yyyy-MM-dd'),
            "end_date": activeTabID === 1 ? format(selectedEndDate, 'yyyy-MM-dd') : format(multiProductEndDate, 'yyyy-MM-dd'),
            "rules": activeTabID === 1 ? rules : multiProductRules,
            "image_url": activeTabID === 1 ? image : multiProductImage,
            "target": activeTabID === 1 ?
                {
                    "competition_target_id": props.detailData.target[0].competition_target_id,
                    "product_id": targetProduct.value,
                    "sales_target": parseInt(salesTarget)
                }
                :
                product_data,
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                _handleCloseModal();
                props.success();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                })
            }
            setCreateLoader(false);
        });
    };

    return (
        <div className="modal fade"
            id="manage-competition-modal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="manageCompetitionModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable e-border-radius-24">
                <div className="modal-content e-border-radius-12 overflow-hidden">
                    <img src={require("../../Assets/Image/CountryAdmin/close-icon.svg").default}
                        draggable={false}
                        id='manage-competition-modal-close'
                        className='d-none '
                        alt='close'
                        data-bs-dismiss="modal" />
                    <div className="modal-header align-items-start flex-column px-4 pt-4 padding-20px-bottom border-0">
                        <div className='d-flex justify-content-between w-100 mb-2'>
                            <h4 className="mb-0 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px">
                                {props.edit === true ? "Edit competition" : "Create competition"}
                            </h4>
                            <Icon icon="close-icon"
                                className='cursor-pointer'
                                size="24px"
                                onPress={_handleCloseModal} />
                        </div>
                        <p className='e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        </p>

                        {/* Competition product select tabs */}
                        <div className='d-flex gap-0-20px'>
                            {
                                TABS.map((tab) => (
                                    <span className={`${activeTabID == tab.id ? "e-text-lust e-border-bottom-2px-lust " : "e-text-raisin-black cursor-pointer"}   e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-28px`}
                                        onClick={() => _handleTabChange(tab.id)}>
                                        {tab.label}
                                    </span>
                                ))
                            }
                        </div>


                        {/* Multi product competition sales target select checkbox */}
                        {
                            activeTabID == 2 &&
                            <div div className='mt-3'>
                                <CustomCheckbox type={2}
                                    checked={isSaleTargetChecked}
                                    label="Set sale target against product"
                                    handleChange={_handleCheckSetSaleTargetChange} />
                            </div>
                        }

                    </div>

                    {
                        props.loading ?
                            <Loader className="h-40vh" /> //loader
                            :
                            activeTabID == 1 ?
                                <div className={`${styles.e_modal_body_wrapper} pt-0 modal-body ps-4 pe-3 me-2 pb-0 overflow-auto e-modal-body-wrapper`}>
                                    <div className={`${startDateToggle === true ? 'padding-60px-bottom' : 'pb-2'} `}>
                                        {/* Title*/}
                                        <div id="single-product-title">
                                            <CustomTextInput label="Competition title"
                                                placeholder="Competition title"
                                                type="text"
                                                require
                                                value={title}
                                                error={titleError}
                                                className="w-100 padding-10px-tb"
                                                labelStyle="e-font-weight-500 e-text-raisin-black"
                                                handleChange={_handleChangeTitle} />
                                        </div>

                                        {/* Description */}
                                        <div id="single-product-description">
                                            <TextArea label="Competition description"
                                                placeholder="Enter your text..."
                                                type="text"
                                                rows="3"
                                                require
                                                value={description}
                                                error={descriptionError}
                                                className=" px-3 padding-10px-tb w-100"
                                                handleChange={_handleDescriptionChange} />
                                        </div>

                                        {/* Rewards points */}
                                        <div id="single-product-reward">
                                            <CustomTextInput label="Rewards point"
                                                placeholder="Rewards point"
                                                type="number"
                                                require
                                                value={rewards}
                                                error={rewardsError}
                                                className="w-100 padding-10px-tb"
                                                labelStyle="e-font-weight-500 e-text-raisin-black"
                                                handleChange={_handleRewardPointChange} />
                                        </div>

                                        {/* Target product */}
                                        <div className='row'>
                                            {/* Target product selector */}
                                            <div className='col-6'
                                                id="single-product-target-product">
                                                <CustomSelectBox label="Target product"
                                                    value={targetProduct}
                                                    require
                                                    options={props.product}
                                                    error={targetProductError}
                                                    className="e-modal-select"
                                                    labelClass="padding-5px-bottom"
                                                    isSearchable={true}
                                                    onMenuOpen={() => _handleScroll("single-product-target-product")}
                                                    selectChange={(id) => { setTargetProduct(id); }}
                                                />
                                            </div>
                                            {/* Sales target units */}
                                            <div className='col-6' id="single-product-target-sales">
                                                <CustomTextInput label="Sales target (units)"
                                                    placeholder="50"
                                                    type="number"
                                                    require
                                                    value={salesTarget}
                                                    error={salesTargetError}
                                                    className="w-100 padding-10px-tb"
                                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                                    handleChange={(e) => setSalesTarget(e)} />
                                            </div>
                                        </div>

                                        {/* date select */}
                                        <div className='row'>
                                            {/* start date picker */}
                                            <div className='col-6 pe-2' id="single-product-startDate">
                                                <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                    Start date<span className='e-text-lust'>*</span>
                                                </p>

                                                <div className='pt-2 h-70px position-relative'>
                                                    <div className={`${startDateToggle ? 'e-border-1px-indigo' : 'e-border-1px-platinum-light'} e-display-flex gap-0-4px e-border-radius-6 padding-14px-tb px-2 cursor-pointer`}
                                                        onClick={_handleStartDateToggle}>
                                                        <img src={require("../../Assets/Image/CountryAdmin/Notification/calendar-icon.svg").default}
                                                            draggable={false}
                                                            alt='calender-icon' />
                                                        {
                                                            selectedStartDate !== null ?
                                                                <>
                                                                    <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                                        {format(selectedStartDate, 'PP')}
                                                                    </p>

                                                                </>
                                                                :
                                                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                                    Choose start date
                                                                </p>
                                                        }

                                                    </div>
                                                    <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px padding-2-top'>
                                                        {startDateError}
                                                    </p>

                                                    {
                                                        selectedStartDate !== null &&
                                                        <Icon icon="close-icon"
                                                            className='position-absolute top-20px right-8px cursor-pointer'
                                                            size="20px"
                                                            onPress={() => {
                                                                setSelectedStartDate(null);
                                                                setSelectedEndDate(null);
                                                            }} />
                                                    }
                                                </div>

                                                {/* date picker */}
                                                {
                                                    startDateToggle &&
                                                    <div className="modal fade" id="start-date-modal" data-bs-backdrop="false" tabIndex="-1" aria-labelledby="starDateModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered e-border-radius-24">
                                                            <button id='start-date-modal-close'
                                                                className='d-none '
                                                                data-bs-dismiss="modal" />
                                                            <div className="modal-content w-358px  mx-auto border-0 bg-transparent">
                                                                <OverLay className="e-bg-overlay " isOpen={startDateToggle} onClose={_handleCloseStartDateModal} />
                                                                <CustomDatePicker type="modal"
                                                                    selected={selectedStartDate}
                                                                    onSelect={_handleSelectedStartDate} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            {/* End date picker */}
                                            <div className='col-6 ps-2' id="single-product-endDate">
                                                <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                    End date<span className='e-text-lust'>*</span>
                                                </p>
                                                <div className='pt-2 h-70px position-relative'>
                                                    <div className={`${endDateToggle ? 'e-border-1px-indigo' : 'e-border-1px-platinum-light'} ${selectedStartDate === null ? 'e-disable' : ''} e-display-flex gap-0-4px e-border-radius-6 padding-14px-tb px-2 cursor-pointer`}
                                                        onClick={_handleEndDateToggle}>
                                                        <img src={require("../../Assets/Image/CountryAdmin/Notification/calendar-icon.svg").default}
                                                            draggable={false}
                                                            alt='calender-icon' />
                                                        {
                                                            selectedEndDate !== null ?
                                                                <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                                    {format(selectedEndDate, 'PP')}
                                                                </p>
                                                                :
                                                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                                    Choose end date
                                                                </p>
                                                        }
                                                    </div>
                                                    <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px padding-2-top'>
                                                        {endDateError}
                                                    </p>

                                                    {
                                                        selectedEndDate !== null &&
                                                        <Icon icon="close-icon"
                                                            className='position-absolute top-20px right-8px cursor-pointer'
                                                            size="20px"
                                                            onPress={() => {
                                                                setSelectedEndDate(null);
                                                            }} />
                                                    }

                                                </div>

                                                {/*End date picker */}
                                                {
                                                    endDateToggle &&
                                                    <div className="modal fade" id="end-date-modal" data-bs-backdrop="false" tabIndex="-1" aria-labelledby="endDateModalLabel" aria-hidden="true" >
                                                        <div className="modal-dialog modal-dialog-centered e-border-radius-24">
                                                            <button id='end-date-modal-close'
                                                                className='d-none '
                                                                data-bs-dismiss="modal" />
                                                            <div className="modal-content w-358px  mx-auto border-0 bg-transparent">
                                                                <OverLay className="e-bg-overlay " isOpen={endDateToggle} onClose={_handleCloseEndDateModal} />
                                                                <CustomDatePicker type="modal"
                                                                    selected={selectedEndDate}
                                                                    minDate={selectedStartDate}
                                                                    onSelect={_handleSelectedEndDate} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        {/* Add rules */}
                                        <>
                                            {
                                                rules.map((rule, index) => (
                                                    <div key={index} id={`single-product-rule-${index}`} className='row'>
                                                        <div className="col-11">
                                                            <CustomTextInput
                                                                label={index === 0 ? "Add rules" : ""}
                                                                placeholder={`Rule ${index + 1}`}
                                                                type="text"
                                                                require
                                                                value={rule}
                                                                error={rulesError[index]}
                                                                className="w-100 padding-10px-tb"
                                                                handleChange={(e) => _handleChangeRule(index, e)}
                                                            />

                                                        </div>

                                                        {/* delete rule */}
                                                        <div className={`col-1 text-end ${index === 0 ? 'pt-4 mt-2' : 'pt-1'}`}>
                                                            <img src={require("../../Assets/Image/CountryAdmin/delete.svg").default}
                                                                draggable={false}
                                                                className={rules.length > 1 ? 'cursor-pointer' : 'e-disable'}
                                                                alt='delete'
                                                                onClick={() => _handleRemoveRule(index)} />
                                                        </div>
                                                    </div>
                                                ))}

                                            {/* add more rule */}
                                            {
                                                rules.length < 5 &&
                                                <p className=' d-inline-block mb-0 e-text-indigo e-font-14 e-alt-font-noto-sans e-font-weight-600 line-height-18px cursor-pointer'
                                                    onClick={_handleAddMoreRule} >
                                                    + <span className='cursor-pointer text-decoration-hover-underline'>Set new rule</span>
                                                </p>
                                            }
                                        </>

                                        {/* file upload */}
                                        <div className='pt-1'
                                            id="single-product-image">
                                            <CustomFileUpload label="Upload image"
                                                placeholder="Drop your image here"
                                                require
                                                resolution="1548px * 477px"
                                                requiredWidth={1548}
                                                requiredHeight={477}
                                                file={image}
                                                fileType={["JPG", "PNG", "JPEG"]}
                                                error={imageError}
                                                loader={imageUploadLoader}
                                                errorUpdate={errorUpdate}
                                                handleChange={_uploadedImage} />

                                            {/* change image button */}

                                            {
                                                image !== null &&
                                                <div className='mb-2 margin-neg-20px-top'>
                                                    <TextButton name="Change image"
                                                        onPress={_handleChangeImage} />
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                                :
                                <div className={`${styles.e_modal_body_wrapper} pt-0 modal-body ps-4 pe-3 me-2 pb-0 h-422px overflow-auto e-modal-body-wrapper`}>
                                    <div className={`${startDateToggle === true ? 'padding-60px-bottom' : 'pb-2'} `}>
                                        {/* Title*/}
                                        <div id="multi-product-title">
                                            <CustomTextInput label="Competition title"
                                                placeholder="Competition title"
                                                type="text"
                                                require
                                                value={multiProductTitle}
                                                error={multiProductTitleError}
                                                className="w-100 padding-10px-tb"
                                                labelStyle="e-font-weight-500 e-text-raisin-black"
                                                handleChange={_handleChangeTitle} />
                                        </div>

                                        {/* Description */}
                                        <div id='multi-product-description'>
                                            <TextArea label="Competition description"
                                                placeholder="Enter your text..."
                                                type="text"
                                                rows="3"
                                                require
                                                value={multiProductDescription}
                                                error={multiProductDescriptionError}
                                                className=" px-3 padding-10px-tb w-100"
                                                handleChange={_handleDescriptionChange} />
                                        </div>

                                        {/* Rewards points */}
                                        <div id='multi-product-reward'>
                                            <CustomTextInput label="Rewards point"
                                                placeholder="Rewards point"
                                                type="number"
                                                require
                                                value={multiProductReward}
                                                error={multiProductRewardError}
                                                className="w-100 padding-10px-tb"
                                                labelStyle="e-font-weight-500 e-text-raisin-black"
                                                handleChange={_handleRewardPointChange} />
                                        </div>

                                        {/* Multi product inputs */}
                                        <>

                                            {
                                                multiTargetProducts.map((target_product, index) => (
                                                    <div className='row align-items-center'
                                                        key={index}>

                                                        {/* Target product selector */}
                                                        <div className={`${isSaleTargetChecked ? "col-6" : "col-11"}`}
                                                            id={`multi-product-${index}`}>
                                                            <CustomSelectBox label="Target product"
                                                                value={target_product}
                                                                require
                                                                options={props.product}
                                                                error={multiTargetProductsError[index]}
                                                                className="e-modal-select"
                                                                labelClass="padding-5px-bottom"
                                                                isSearchable={true}
                                                                onMenuOpen={() => _handleScroll(`multi-product-${index}`)}
                                                                selectChange={(selected) => _handleMultiProductSelect(selected, index)}
                                                            />
                                                        </div>

                                                        {/* Sales target units */}
                                                        {
                                                            isSaleTargetChecked === true &&
                                                            <div className='col-5'
                                                                id={`multi-product-sales-target-${index}`}>
                                                                <CustomTextInput label="Sales target (units)"
                                                                    placeholder="50"
                                                                    type="number"
                                                                    require
                                                                    value={multiProductsSalesTarget[index]}
                                                                    error={multiProductsSalesTargetError[index]}
                                                                    className="w-100 padding-10px-tb"
                                                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                                                    handleChange={(value) => _handleChangeMultiProductSalesTarget(value, index)} />
                                                            </div>
                                                        }

                                                        {/* Remove added product - icon */}
                                                        {
                                                            multiTargetProducts.length > 1 &&
                                                            <div className={`col-1 ps-0 text-end`}>
                                                                <img src={require("../../Assets/Image/CountryAdmin/delete.svg").default}
                                                                    draggable={false}
                                                                    className='cursor-pointer'
                                                                    alt='delete'
                                                                    onClick={() => _handleRemoveProduct(index)} />
                                                            </div>
                                                        }
                                                    </div>
                                                ))
                                            }

                                            {/* Add more product button */}
                                            {
                                                multiTargetProducts.length < 5 &&
                                                <p className='d-inline-block mb-3 e-text-indigo e-font-14 e-alt-font-noto-sans e-font-weight-600 line-height-18px cursor-pointer'
                                                    onClick={_handleAddMoreProduct} >
                                                    + <span className='cursor-pointer text-decoration-hover-underline'>Add new product</span>
                                                </p>
                                            }

                                            {/* Total Sales target units */}
                                            {/* <div className='col-12'
                                                id={`multi-product-total-sales-target`}>
                                                <CustomTextInput label="Total sales target (units)"
                                                    placeholder="50"
                                                    type="number"
                                                    require
                                                    value={totalSalesTarget}
                                                    error={totalSalesTargetError}
                                                    className="w-100 padding-10px-tb col-12"
                                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                                    handleChange={_handleTotalSalesTargetChange} />
                                            </div> */}
                                        </>

                                        {/* date select */}
                                        <div className='row'>
                                            {/* start date picker */}
                                            <div className='col-6 pe-2'
                                                id="multi-product-startDate">
                                                <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                    Start date<span className='e-text-lust'>*</span>
                                                </p>

                                                <div className='pt-2 h-70px position-relative'>
                                                    <div className={`${startDateToggle ? 'e-border-1px-indigo' : 'e-border-1px-platinum-light'} e-display-flex gap-0-4px e-border-radius-6 padding-14px-tb px-2 cursor-pointer`}
                                                        onClick={_handleStartDateToggle}>
                                                        <img src={require("../../Assets/Image/CountryAdmin/Notification/calendar-icon.svg").default}
                                                            draggable={false}
                                                            alt='calender-icon' />
                                                        {
                                                            multiProductStartDate !== null ?
                                                                <>
                                                                    <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                                        {format(multiProductStartDate, 'PP')}
                                                                    </p>

                                                                </>
                                                                :
                                                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                                    Choose start date
                                                                </p>
                                                        }

                                                    </div>
                                                    <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px padding-2-top'>
                                                        {multiProductStartDateError}
                                                    </p>

                                                    {
                                                        multiProductStartDate !== null &&
                                                        <Icon icon="close-icon"
                                                            className='position-absolute top-20px right-8px cursor-pointer'
                                                            size="20px"
                                                            onPress={() => {
                                                                setMultiProductEndDate(null);
                                                                setMultiProductStartDate(null);
                                                            }} />
                                                    }
                                                </div>

                                                {/* date picker */}
                                                {
                                                    startDateToggle &&
                                                    <div className="modal fade" id="start-date-modal" data-bs-backdrop="false" tabIndex="-1" aria-labelledby="starDateModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered e-border-radius-24">
                                                            <button id='start-date-modal-close'
                                                                className='d-none '
                                                                data-bs-dismiss="modal" />
                                                            <div className="modal-content w-358px  mx-auto border-0 bg-transparent">
                                                                <OverLay className="e-bg-overlay " isOpen={startDateToggle} onClose={_handleCloseStartDateModal} />
                                                                <CustomDatePicker type="modal"
                                                                    selected={multiProductStartDate}
                                                                    onSelect={_handleSelectedStartDate} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>

                                            {/* End date picker */}
                                            <div className='col-6 ps-2'
                                                id={`multi-product-endDate`}>
                                                <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                    End date<span className='e-text-lust'>*</span>
                                                </p>
                                                <div className='pt-2 h-70px position-relative'>
                                                    <div className={`${endDateToggle ? 'e-border-1px-indigo' : 'e-border-1px-platinum-light'} ${multiProductStartDate === null ? "e-disable" : ''} e-display-flex gap-0-4px e-border-radius-6 padding-14px-tb px-2 cursor-pointer`}
                                                        onClick={_handleEndDateToggle}>
                                                        <img src={require("../../Assets/Image/CountryAdmin/Notification/calendar-icon.svg").default}
                                                            draggable={false}
                                                            alt='calender-icon' />
                                                        {
                                                            multiProductEndDate !== null ?
                                                                <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                                    {format(multiProductEndDate, 'PP')}
                                                                </p>
                                                                :
                                                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                                    Choose end date
                                                                </p>
                                                        }
                                                    </div>
                                                    <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px padding-2-top'>
                                                        {multiProductEndDateError}
                                                    </p>

                                                    {
                                                        multiProductEndDate !== null &&
                                                        <Icon icon="close-icon"
                                                            className='position-absolute top-20px right-8px cursor-pointer'
                                                            size="20px"
                                                            onPress={() => setMultiProductEndDate(null)} />
                                                    }
                                                </div>

                                                {/*End date picker */}
                                                {
                                                    endDateToggle &&
                                                    <div className="modal fade" id="end-date-modal" data-bs-backdrop="false" tabIndex="-1" aria-labelledby="endDateModalLabel" aria-hidden="true" >
                                                        <div className="modal-dialog modal-dialog-centered e-border-radius-24">
                                                            <button id='end-date-modal-close'
                                                                className='d-none '
                                                                data-bs-dismiss="modal" />
                                                            <div className="modal-content w-358px  mx-auto border-0 bg-transparent">
                                                                <OverLay className="e-bg-overlay " isOpen={endDateToggle} onClose={_handleCloseEndDateModal} />
                                                                <CustomDatePicker type="modal"
                                                                    selected={multiProductEndDate}
                                                                    minDate={multiProductStartDate}
                                                                    onSelect={_handleSelectedEndDate} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>



                                        {/* Add rules */}

                                        <>
                                            {
                                                multiProductRules.map((rule, index) => (
                                                    <div id={`multi-product-rule-${index}`}
                                                        key={index}
                                                        className='row'>
                                                        <div className="col-11">
                                                            <CustomTextInput
                                                                label={index === 0 ? "Add rules" : ""}
                                                                placeholder={`Rule ${index + 1}`}
                                                                type="text"
                                                                require
                                                                value={rule}
                                                                error={multiProductRulesErrors[index]}
                                                                className="w-100 padding-10px-tb"
                                                                handleChange={(e) => _handleChangeMultiProductRule(index, e)}
                                                            />

                                                        </div>

                                                        {/* delete rule */}
                                                        {
                                                            multiProductRules.length > 1 &&
                                                            <div className={`col-1 text-end ${index === 0 ? 'pt-4 mt-2' : 'pt-1'}`}>
                                                                <img src={require("../../Assets/Image/CountryAdmin/delete.svg").default}
                                                                    draggable={false}
                                                                    className='cursor-pointer'
                                                                    alt='delete'
                                                                    onClick={() => _handleRemoveMultiProductRule(index)} />
                                                            </div>
                                                        }
                                                    </div>
                                                ))}

                                            {/* add more rule */}
                                            {
                                                multiProductRules.length < 5 &&
                                                <p className='d-inline-block mb-0 e-text-indigo e-font-14 e-alt-font-noto-sans e-font-weight-600 line-height-18px cursor-pointer'
                                                    onClick={_handleAddMoreMultiProductRule} >
                                                    + <span className='cursor-pointer text-decoration-hover-underline'>Set new rule</span>
                                                </p>
                                            }
                                        </>

                                        {/* file upload */}
                                        <div className='pt-1'
                                            id="multi-product-image">
                                            <CustomFileUpload label="Upload image"
                                                placeholder="Drop your image here"
                                                require
                                                resolution="1548px * 477px"
                                                requiredWidth={1548}
                                                requiredHeight={477}
                                                file={multiProductImage}
                                                fileType={["JPG", "PNG", "JPEG"]}
                                                error={multiProductImageError}
                                                loader={imageUploadLoader}
                                                errorUpdate={errorUpdate}
                                                handleChange={_uploadedImage} />

                                            {/* change image button */}
                                            {
                                                multiProductImage !== null &&
                                                <div className='mb-2 margin-neg-20px-top'>
                                                    <TextButton name="Change image"
                                                        onPress={_handleChangeImage} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                    }

                    {/* Action Buttons */}
                    <div className='row pt-3 px-4 pb-4'>
                        <div className='col-6'>
                            <SecondaryButton name="Cancel"
                                className="w-100"
                                onPress={_handleCloseModal} />
                        </div>
                        <div className='col-6'>
                            <PrimaryButton name={props.edit === true ? "Update" : "Create"}
                                className="w-100"
                                status={createLoader}
                                onPress={_handleCreateCompetition} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
});


ManageCompetitionModal.defaultProps = {
    closeModal: () => { }
}

export default ManageCompetitionModal;