/*
 *   File :doughnut-bar.js
 *   Author : https://evoqins.com
 *   Description : Doughnut chart component
 *   Integrations : react-chartjs-2,chart.js
 *   Version : 1.0.0
*/

import React from 'react';

import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js'

Chart.register(ArcElement);

// Customized tooltip
const createTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = '#000';
        tooltipEl.style.borderRadius = '4px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.width = 'max-content'
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';

        const table = document.createElement('table');
        table.style.margin = '0px';
        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};

const externalTooltip = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = createTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }
    if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map(b => b.lines);

        const tableHead = document.createElement('thead');

        titleLines.forEach(title => {
            const tr = document.createElement('tr');
            tr.style.borderWidth = 0;

            const th = document.createElement('th');
            th.style.borderWidth = 0;
            th.style.color = "#fff";
            th.style.fontFamily = "Noto Sans";
            th.style.fontSize = "12px";
            th.style.fontWeight = "500";

            th.style.padding = 0;
            const text = document.createTextNode(title);

            th.appendChild(text);
            tr.appendChild(th);
            tableHead.appendChild(tr);
        });

        const tableBody = document.createElement('tbody');
        bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];

            const span = document.createElement('span');
            span.style.background = colors.backgroundColor;
            span.style.borderColor = colors.borderColor;
            span.style.borderWidth = '2px';
            span.style.marginRight = '4px';
            span.style.height = '10px';
            span.style.width = '10px';
            span.style.display = 'inline-block';
            const tr = document.createElement('tr');
            tr.style.backgroundColor = 'inherit';
            tr.style.borderWidth = 0;

            const td = document.createElement('td');
            td.style.borderWidth = 0;
            td.style.padding = 0;
            td.style.fontFamily = "Noto Sans";
            td.style.fontSize = "10px";
            td.style.fontWeight = "400";

            const text = document.createTextNode(body);

            td.appendChild(span);
            td.appendChild(text);
            tr.appendChild(td);
            tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector('table');

        // Remove old children
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipEl.style.opacity = 1;
    tooltipEl.style.zIndex = 999;
    tooltipEl.style.left = positionX + tooltip.caretX + 40 + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';

    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};

const DoughnutChart = (props) => {

    const DATA = {
        labels: props.label,
        datasets: [{
            label: '',
            data: props.data,
            cutout: ['80%'],
            backgroundColor: ['#F5B01A', '#E02126', '#213366'],
            // hoverOffset: 2,
            borderWidth: 0,
            barThickness: 26,
        }],
        text: '45'
    };

    const OPTIONS = {
        type: 'doughnut',
        data: DATA,
        responsive: true,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false
            },
            tooltip: {
                enabled: false,
                position: 'nearest',
                external: externalTooltip
            },


        },
        barThickness: 26,
    };

    const UPDATED = [{
        afterUpdate: function (chart) {
            const arcs = chart.getDatasetMeta(0).data;

            arcs.forEach(function (arc) {
                arc.round = {
                    x: (chart.chartArea.left + chart.chartArea.right) / 2,
                    y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
                    radius: (arc.outerRadius + arc.innerRadius) / 2,
                    thickness: (arc.outerRadius - arc.innerRadius) / 2,
                    backgroundColor: arc.options.backgroundColor
                }
            });
        },
        afterDraw: (chart) => {
            const { ctx } = chart;

            chart.getDatasetMeta(0).data.forEach(arc => {
                // const startAngle = Math.PI / 2 - arc.startAngle;
                const endAngle = Math.PI / 2 - arc.endAngle + 0.05;
                ctx.save();
                ctx.translate(arc.round.x, arc.round.y);
                ctx.fillStyle = arc.options.backgroundColor;
                ctx.beginPath();
                ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
                ctx.closePath();
                ctx.fill();
                ctx.restore();

            });
        },
        beforeDraw: function (chart) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();

            var fontSize = (height / 190).toFixed(2);
            var fontWeight = "600";
            ctx.font = `${fontWeight} ${fontSize}em Noto Sans`;
            ctx.textBaseline = "top";
            ctx.fillStyle = "#212121";

            var text = props.data[0] + props.data[1] + props.data[2],
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2 - 20;
            ctx.fillText(text, textX, textY);

            // Styling
            fontSize = (height / 280).toFixed(2);
            fontWeight = "400";
            ctx.font = `${fontWeight} ${fontSize}em Noto Sans`;
            ctx.fillStyle = "#555555";

            var text2 = "Products sold",
                text2X = Math.round((width - ctx.measureText(text2).width) / 2),
                text2Y = height / 2 + 4;
            ctx.fillText(text2, text2X, text2Y);

            ctx.save();
        }
    }]

    return (
        <div className='h-210px w-210px'>
            <Doughnut data={DATA}
                options={OPTIONS}
                plugins={UPDATED} />
        </div>
    )
}

export default DoughnutChart;