/*
 *   File : add-banner.js
 *   Author : https://evoqins.com
 *   Description : Add banner modal
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// Custom components
import { CustomFileUpload, CustomSelectBox, CustomTextInput } from '../FormElements';
import { PrimaryButton, SecondaryButton, TextButton } from '../Buttons';
import { APIService, AWSService } from '../../Service';
import { Icon } from '../Icon';

// style 
import styles from "../../Style/Component/modal.module.scss";

const TYPE = [
    {
        value: 1,
        label: 'IN-APP',
    },
    {
        value: 2,
        label: 'External'
    },
];

const ManageBanner = (props) => {

    const [image, setImage] = useState(null);
    const [imageError, setImageError] = useState("");
    const [success, setSuccess] = useState(false);
    const [selectedType, setSelectedType] = useState(TYPE[0]);
    const [selectedResource, setSelectedResource] = useState(props.resourceOptions[0]);
    const [selectedResourceError, setSelectedResourceError] = useState("");

    const [selectedData, setSelectedData] = useState(null);
    const [selectedDataError, setSelectedDataError] = useState("");

    const [externalUrl, setExternalUrl] = useState("");
    const [editData, setEditData] = useState(props.editData);
    const [loading, setLoading] = useState(true);
    const [uploadLoader, setUploadLoader] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [errorUpdate, setErrorUpdate] = useState(false);

    useEffect(() => {
        setEditData(props.editData);
    }, [props.editData]);

    useEffect(() => {
        if (editData.length !== 0) {
            setImage(editData.data.image);
            setSelectedType({ value: editData.data.type_id, label: editData.data.type });
            if (editData.data.type_id === 1) {
                setSelectedResource({ value: editData.data.content_id, label: editData.data.content_name })
                setSelectedData({ value: editData.data.data_id, label: editData.data.data_name });
            } else {
                setExternalUrl(editData.data.url)
            }
        }

    }, [editData]);

    useEffect(() => {
        setImageError("");
    }, [image]);

    useEffect(() => {
        setSelectedResourceError("");
    }, [selectedResource]);

    useEffect(() => {
        setSelectedDataError("");
    }, [selectedData]);

    // handle upload scheduled image
    const _uploadedImage = (url) => {
        setUploadLoader(true);
        _getPresignedUrl(url);
    };

    // handle close Modal 
    const _handleCloseModal = () => {
        var button = document.getElementById("add-offer-modal-close");
        button.click();
        setImageError("");
        setExternalUrl("");
        setImage(null);
        setSelectedResource(null);
        setSelectedData(null);
        setSuccess(false);
        props.handleClose();
    };

    // handle success
    const _handleSuccess = () => {
        var button = document.getElementById("add-offer-modal-close");
        button.click();
        setImageError("");
        setExternalUrl("");
        setImage(null);
        setSelectedResource(null);
        setSelectedData(null);
        setSuccess(false);
        props.success();
    };

    // handle select resource type
    const _handleSelectedResourceType = (value) => {
        setSelectedResource(value);

        setSelectedData(null);
    };

    // handle submit offer
    const _handleSubmit = () => {

        let is_valid = true;

        if (image === null) {
            setImageError("Image is required");
            is_valid = false;
        }

        if (selectedType.value === 1 && selectedData === null) {
            setSelectedDataError("Select data");
            is_valid = false;
        }

        setErrorUpdate(prev => !prev);

        if (is_valid === true) {
            setLoading(false);

            if (editData.type === "edit") {
                _updateBanner();
            } else {
                _createBanner();
            }
        }
    };

    // handle delete banner 
    const _handleDeleteBanner = (id) => {
        props.handleDelete(id);
        _handleCloseModal();
    }

    // get presigned url
    async function _getPresignedUrl(file) {
        let is_post = true;
        let url = 'general/file-upload';
        let data = {
            "file": file.name,
            "is_public": true
        };
        try {
            let response = await APIService(is_post, url, data);
            if (response.status_code == 200) {

                let aws_fields = response.data.upload_info.fields;
                let aws_url = response.data.upload_info.url;

                try {
                    let aws_response = await AWSService(aws_fields, file, aws_url);
                    if (aws_response.status == 'ok') {
                        setImage(response.data.view_info);
                    } else {
                        toast.dismiss();
                        toast.error(aws_response.message, {
                            type: "error",
                            className: "e-toast",
                        });
                    }
                } catch (err) {
                    console.log("error")
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        } catch (err) {
        }
        setUploadLoader(false);
    };

    // API - Create banner
    const _createBanner = () => {
        let is_post = true;
        let url = 'CA/banner/create';
        let data = {
            "image": image,
            "type": selectedType.value,
            "content_id": selectedType.value === 1 ? selectedResource.value : null,
            "data_id": selectedType.value === 1 ? selectedData.value : null,
            "external_url": externalUrl.length !== 0 ? externalUrl : null
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setLoading(true);
        });
    };

    // API - Edit banner
    const _updateBanner = () => {
        let is_post = true;
        let url = 'CA/banner/edit';
        let data = {
            "banner_id": editData.data.banner_id,
            "image": image,
            "type": selectedType.value,
            "content_id": selectedType.value === 1 ? selectedResource.value : null,
            "data_id": selectedType.value === 1 ? selectedData.value : null,
            "url": externalUrl.length !== 0 ? externalUrl : null
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess(true);

            } else {
            }
            setLoading(true);
        });
    };

    return (
        <div className="modal fade" id="add-banner-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addBannerModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content e-border-radius-12 w-480px mx-auto ">
                    <img src={require("../../Assets/Image/CountryAdmin/close-icon.svg").default}
                        id='add-offer-modal-close'
                        className='d-none'
                        alt='close'
                        data-bs-dismiss="modal" />
                    {!success ?
                        <>
                            <div className="modal-header px-4 pt-4 pb-2 border-0">
                                <h4 className="mb-0 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px">
                                    {editData.type === "edit" ? 'Edit banner' : 'Add new banner'}
                                </h4>
                                <Icon icon="close-icon"
                                    className='cursor-pointer'
                                    size="24px"
                                    onPress={_handleCloseModal} />
                            </div>
                            <div className={`modal-body ps-0 pe-3 pt-0 pb-4 e-modal-body-wrapper`}>
                                <div className={`${styles.e_modal_body_wrapper} ${menuOpen ? "overflow-hidden" : ""} ps-4 pb-3 pe-2 h-392px overflow-auto `}>
                                    <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    </p>
                                    <div className="pt-2">

                                        {/* upload thumbnail */}
                                        <CustomFileUpload label="Add banner image"
                                            placeholder="Drop your image here"
                                            file={image}
                                            fileType={["JPG", "PNG", "JPEG"]}
                                            loader={uploadLoader}
                                            require
                                            resolution="328px * 138px"
                                            requiredWidth={328}
                                            requiredHeight={138}
                                            error={imageError}
                                            errorUpdate={errorUpdate}
                                            handleChange={_uploadedImage} />

                                        {/* change thumbnail button */}
                                        {image !== null &&
                                            <div className='margin-neg-16px-top'>
                                                <TextButton name="Change Image"
                                                    onPress={() => setImage(null)} />
                                            </div>
                                        }

                                        {/* Type select */}
                                        <CustomSelectBox label="Type"
                                            value={selectedType}
                                            options={TYPE}
                                            className="e-modal-select"
                                            isSearchable={false}
                                            selectChange={(id) => { setSelectedType(id); }}
                                        />

                                        {selectedType.label === "IN-APP" ?
                                            <>
                                                {/* select resource type  */}
                                                <CustomSelectBox label="Resource type"
                                                    value={selectedResource}
                                                    options={props.resourceOptions}
                                                    error={selectedResourceError}
                                                    menuPortalTarget={document.body}
                                                    className="e-modal-select"
                                                    isSearchable={false}
                                                    selectChange={(id) => _handleSelectedResourceType(id)} />

                                                <>
                                                    {/* Course select */}
                                                    {selectedResource.label === "Course" ?
                                                        <CustomSelectBox label="Course"
                                                            value={selectedData}
                                                            options={props.courseList}
                                                            error={selectedDataError}
                                                            className="e-modal-select"
                                                            menuIsOpen={menuOpen}
                                                            menuPortalTarget={document.body}
                                                            isSearchable={true}
                                                            onMenuOpen={() => setMenuOpen(true)}
                                                            onMenuClose={() => setMenuOpen(false)}
                                                            selectChange={(id) => { setSelectedData(id); }}
                                                        />
                                                        :
                                                        // News select
                                                        selectedResource.label === "News" ?
                                                            <CustomSelectBox label="News"
                                                                value={selectedData}
                                                                options={props.newsOptions}
                                                                error={selectedDataError}
                                                                className="e-modal-select"
                                                                menuIsOpen={menuOpen}
                                                                menuPortalTarget={document.body}
                                                                isSearchable={true}
                                                                onMenuOpen={() => setMenuOpen(true)}
                                                                onMenuClose={() => setMenuOpen(false)}
                                                                selectChange={(id) => { setSelectedData(id); }} />
                                                            :
                                                            // Competition select
                                                            // <CustomSelectBox label="Competition"
                                                            //     value={selectedData}
                                                            //     options={props.competitionOptions}
                                                            //     error={selectedDataError}
                                                            //     className="e-modal-select"
                                                            //     menuIsOpen={menuOpen}
                                                            //     menuPortalTarget={document.body}
                                                            //     isSearchable={true}
                                                            //     onMenuOpen={() => setMenuOpen(true)}
                                                            //     onMenuClose={() => setMenuOpen(false)}
                                                            //     selectChange={(id) => { setSelectedData(id); }} />
                                                            null
                                                    }
                                                </>
                                            </>
                                            :

                                            // External url
                                            <div >
                                                <CustomTextInput label="External url"
                                                    placeholder="Url"
                                                    type="text"
                                                    value={externalUrl}
                                                    className="w-100"
                                                    labelStyle="pb-1 e-font-weight-500 e-text-raisin-black"
                                                    handleChange={(e) => setExternalUrl(e)} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                {/* Custom button  */}
                                <div className={`${editData.type === "edit" ? 'row row-cols-2' : ''} mt-4 ps-4 pe-2`}>
                                    {editData.type === "edit" &&
                                        <div className='col'>
                                            <SecondaryButton name="Delete"
                                                className="w-100"
                                                onPress={() => _handleDeleteBanner(editData.data.banner_id)} />
                                        </div>
                                    }
                                    <div className='col'>
                                        <PrimaryButton name={editData.type === "edit" ? "Update" : "Save banner"}
                                            className="w-100"
                                            status={!loading}
                                            onPress={_handleSubmit} />
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="modal-header px-4 pt-4 pb-2 border-0 align-items-start">
                                <img src={require("../../Assets/Image/CountryAdmin/success-icon.svg").default}
                                    draggable={false}
                                    alt='icon' />
                                <Icon icon="close-icon"
                                    className='cursor-pointer'
                                    size="24px"
                                    onPress={_handleSuccess} />
                                <button type="button" className="btn-close shadow-none d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body px-4 pb-4 pt-2">
                                <h6 className='mb-2 e-primary-font-playfair e-font-18 e-text-raisin-black e-font-weight-500 line-height-28px'>
                                    {editData.type === "edit" ? 'Banner updated successfully' : 'Banner added successfully'}
                                </h6>
                                <p className='mb-0 e-text-davys-gray e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                    Successfully added the application.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                </p>
                                <div className='pt-4 mt-2'>
                                    <PrimaryButton name="Ok"
                                        className="w-100"
                                        onPress={_handleSuccess} />
                                </div>
                            </div>
                        </>
                    }

                </div>
            </div>
        </div>
    )
}

export default memo(ManageBanner);