import React, { memo, useEffect, useState } from 'react';

// Custom components
import { CustomSearchSuggestion, TextArea } from '../FormElements';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { Loader } from '../Others';
import { Icon } from '../Icon';

const RejectRedemptionModal = (props) => {

    const [rejectStatus, setRejectStatus] = useState(false);
    const [message, setMessage] = useState("");
    const [selected, setSelected] = useState(null);
    const [selectedError, setSelectedError] = useState("");

    useEffect(() => {
        if (props.response === true) {
            setRejectStatus(true);
        }
    }, [props.response]);

    useEffect(() => {
        setSelectedError(props.error);
    }, [props.error]);

    useEffect(() => {
        setSelectedError("");
    }, [selected]);

    // handle select search value 
    const _handleSelectReason = (value) => {
        setSelected(value);
    }

    // handle close modal
    const _handleCloseModal = () => {
        var button = document.getElementById("redemption-modal-close");
        button.click();

        setRejectStatus(false);
        setSelectedError("");
        setMessage("");
        setSelected(null);
        props.handleClose();
    };

    // handle success
    const _handleSuccess = () => {
        var button = document.getElementById("redemption-modal-close");
        button.click();

        setRejectStatus(false);
        setSelected(null);
        props.success();
    };

    // handle reject confirm 
    const _handleRejectConfirm = () => {
        props.handleReject({ selected, message });
    };


    return (
        <div className="modal fade" id="redemption-reject-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="requestRejectModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered e-border-radius-24">
                <div className="modal-content e-border-radius-8">
                    <button type="button"
                        id='redemption-modal-close'
                        className="btn-close shadow-none d-none"
                        data-bs-dismiss="modal" aria-label="Close"></button>
                    {!rejectStatus ?
                        <>
                            {props.reasonLoader ?
                                // loader 
                                <Loader className="h-30vh" />
                                :
                                <>
                                    <div className="modal-header px-4 pt-4 pb-2 border-0">
                                        {/* autocomplete search input */}
                                        <CustomSearchSuggestion placeholder="Search and add reason for rejection"
                                            selected={selected}
                                            error={selectedError}
                                            options={props.reasonData}
                                            handleSelect={_handleSelectReason} />
                                    </div>
                                    <div className="modal-body px-4 pb-4 pt-0">

                                        <TextArea label="Additional information"
                                            placeholder="Enter your text"
                                            type="text"
                                            rows="3"
                                            value={message}
                                            className="w-100 px-3 padding-12px-tb"
                                            handleChange={(e) => setMessage(e)} />

                                        <div className='row pt-2'>
                                            <div className='col-6 pe-1'>
                                                <SecondaryButton name="Cancel"
                                                    className="e-border-radius-8 w-100"
                                                    onPress={_handleCloseModal} />
                                            </div>
                                            <div className='col-6 ps-1'>
                                                <PrimaryButton name="Reject"
                                                    status={props.loading}
                                                    className="e-border-radius-8 w-100"
                                                    onPress={_handleRejectConfirm} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                        :
                        <>
                            <div className="modal-header px-4 pt-4 pb-2 border-0 align-items-start">
                                <img src={require("../../Assets/Image/CountryAdmin/success-icon.svg").default}
                                    draggable={false}
                                    alt='icon' />
                                <Icon icon="close-icon"
                                    className='cursor-pointer'
                                    size="24px"
                                    onPress={_handleSuccess} />
                                <button type="button" className="btn-close shadow-none d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body px-4 pb-4 pt-2">
                                <h6 className='mb-2 e-primary-font-playfair e-font-18 e-text-raisin-black e-font-weight-500 line-height-28px'>
                                    Rejected successfully
                                </h6>
                                <p className='mb-0 e-text-davys-gray e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                    Successfully rejected the application.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                </p>
                                <div className='pt-4 mt-2'>
                                    <PrimaryButton name="Ok"
                                        className="w-100"
                                        onPress={_handleSuccess} />
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default memo(RejectRedemptionModal);