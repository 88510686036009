/*
 *   File : add-rewards.js
 *   Author : https://evoqins.com
 *   Description : Add rewards modal
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// Custom components
import { CustomSelectBox, CustomTextInput } from '../FormElements';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { APIService } from '../../Service';
import { Icon } from '../Icon';

const AddRewardsModal = (props) => {

    const [selectedMilestoneType, setSelectedMilestoneType] = useState(null);
    const [selectedMilestoneTypeError, setSelectedMilestoneTypeError] = useState("");
    const [selectedSalesType, setSelectedSalesType] = useState(null);
    const [selectedSalesTypeError, setSelectedSalesTypeError] = useState("");
    const [salesPerUnit, setSalesPerUnit] = useState("");
    const [salesPerUnitError, setSalesPerUnitError] = useState("");
    const [visitedPainters, setVisitedPainters] = useState("");
    const [visitedPaintersError, setVisitedPaintersError] = useState("");
    const [rewardPoint, setRewardPoint] = useState("");
    const [rewardPointError, setRewardPointError] = useState("");
    const [success, setSuccess] = useState(false);
    const [createLoader, setCreateLoader] = useState(false);

    useEffect(() => {
        setRewardPointError("");
    }, [rewardPoint]);

    useEffect(() => {
        setSelectedMilestoneTypeError("");
        setRewardPointError("");
        setSelectedSalesType(null);
        setRewardPoint("");
        setVisitedPainters("");
        setSalesPerUnit("");
    }, [selectedMilestoneType]);

    useEffect(() => {
        setSelectedSalesTypeError("");
        setRewardPoint("");
        setVisitedPainters("");
        setSalesPerUnit("");
    }, [selectedSalesType]);

    useEffect(() => {
        setSalesPerUnitError("");
    }, [salesPerUnit]);

    useEffect(() => {
        setVisitedPaintersError("");
    }, [visitedPainters]);

    // handle close modal
    const _handleSuccess = () => {
        var button = document.getElementById("add-reward-modal-close");
        button.click();
        setRewardPointError("");
        setRewardPoint("");
        setSelectedMilestoneType(null);
        setSelectedMilestoneTypeError("");
        setSelectedSalesType(null);
        setSelectedSalesTypeError("");
        setSalesPerUnit("");
        setSalesPerUnitError("");
        setVisitedPainters("");
        setVisitedPaintersError("");
        setSuccess(false);
        props.handleClose()
        props.success();
    };

    // handle close modal
    const _handleCloseModal = () => {
        var button = document.getElementById("add-reward-modal-close");
        button.click();
        setRewardPointError("");
        setRewardPoint("");
        setSelectedMilestoneType(null);
        setSelectedMilestoneTypeError("");
        setSelectedSalesType(null);
        setSelectedSalesTypeError("");
        setSalesPerUnit("");
        setSalesPerUnitError("");
        setVisitedPainters("");
        setVisitedPaintersError("");
        setSuccess(false);
        props.handleClose();
    };

    // handle submit
    const _handleSubmit = () => {

        let is_valid = true;

        if (selectedMilestoneType === null) {
            setSelectedMilestoneTypeError("Select milestone type");
            is_valid = false;
        } else if (selectedMilestoneType.value === 1) {
            if (selectedSalesType === null) {
                setSelectedSalesTypeError("Selected sales type is required");
                return;
            }
            if (salesPerUnit.length === 0) {
                setSalesPerUnitError("Sales per unit is required");
                return;
            }

        } else if (selectedMilestoneType.value === 3) {
            if (visitedPainters.length === 0) {
                setVisitedPaintersError("Visited painters is required");
                return;
            }
        }

        if (rewardPoint.length === 0) {
            setRewardPointError("Reward point is required");
            is_valid = false;
        }

        if (is_valid === true) {
            setCreateLoader(true);
            _createMilestone();
        }
    };

    // API - Create milestone 
    const _createMilestone = () => {
        let is_post = true;
        let url = 'CA/milestone/create';
        let data = {
            "milestone_type_id": selectedMilestoneType.value,
            "sales_type_id": selectedMilestoneType.value === 1 ? selectedSalesType.value : null,
            "sales_per_unit": selectedMilestoneType.value === 1 ? salesPerUnit : null,
            "reward_points": rewardPoint,
            "visited_painters": selectedMilestoneType.value === 3 ? visitedPainters : null
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess(true);
                setRewardPoint("");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    return (
        <div className="modal fade" id="add-reward-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addRewardModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered e-border-radius-24">
                <div className="modal-content e-border-radius-12 w-480px mx-auto">
                    <img src={require("../../Assets/Image/CountryAdmin/close-icon.svg").default}
                        draggable={false}
                        id='add-reward-modal-close'
                        className='d-none'
                        alt='close'
                        data-bs-dismiss="modal" />
                    {!success ?
                        <>
                            <div className="modal-header px-4 pt-4 pb-2 border-0">
                                <h4 className="mb-0 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px">
                                    Add new milestone
                                </h4>
                                <Icon icon="close-icon"
                                    className='cursor-pointer'
                                    size="24px"
                                    onPress={_handleCloseModal} />
                            </div>
                            <div className="modal-body px-4 pt-0 pb-4">
                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                </p>
                                <div className="padding-20px-top">
                                    {/* Reward type */}
                                    <CustomSelectBox label="Milestone type"
                                        placeholder="Select milestone type"
                                        value={selectedMilestoneType}
                                        options={props.data.milestone_types}
                                        error={selectedMilestoneTypeError}
                                        className="e-modal-select"
                                        isSearchable={false}
                                        selectChange={(id) => {
                                            setSelectedMilestoneType(id);
                                        }}
                                    />

                                    {/* Sales type */}
                                    {selectedMilestoneType?.value === 1 &&
                                        <div>
                                            <CustomSelectBox label="Sales type"
                                                placeholder="Select sales type"
                                                value={selectedSalesType}
                                                options={props.data.sales_type}
                                                error={selectedSalesTypeError}
                                                className="e-modal-select"
                                                isSearchable={false}
                                                selectChange={(id) => { setSelectedSalesType(id); }}
                                            />
                                        </div>
                                    }

                                    <div className='row '>
                                        <div className='col-6 pe-2'>

                                            {selectedMilestoneType?.value === 1 ?
                                                // Sales(Per unit)
                                                <CustomTextInput label="Sales(Per unit)"
                                                    placeholder="100"
                                                    type="number"
                                                    not_decimal={true}
                                                    require
                                                    value={salesPerUnit}
                                                    error={salesPerUnitError}
                                                    className="w-100"
                                                    labelStyle="e-font-weight-400 e-text-raisin-black line-height-18px padding-6px-bottom"
                                                    handleChange={(e) => setSalesPerUnit(e)} />
                                                :
                                                // Visited painters 
                                                selectedMilestoneType?.value === 3 ?
                                                    <CustomTextInput label="Visited painters"
                                                        placeholder="100"
                                                        type="number"
                                                        not_decimal={true}
                                                        require
                                                        value={visitedPainters}
                                                        error={visitedPaintersError}
                                                        className="w-100"
                                                        labelStyle="e-font-weight-400 e-text-raisin-black line-height-18px padding-6px-bottom"
                                                        handleChange={(e) => setVisitedPainters(e)} />
                                                    :
                                                    null

                                            }
                                        </div>

                                        <div className={`${selectedMilestoneType?.value === 1 || selectedMilestoneType?.value === 3 ? 'col-6 ps-2' : 'col-12'}  `}>
                                            {/* Reward point */}
                                            <CustomTextInput label="Reward point"
                                                placeholder="1500"
                                                type="number"
                                                not_decimal={true}
                                                require
                                                value={rewardPoint}
                                                error={rewardPointError}
                                                className="w-100"
                                                labelStyle="e-font-weight-400 e-text-raisin-black line-height-18px padding-6px-bottom"
                                                handleChange={(e) => setRewardPoint(e)}
                                                onKeyPress={_handleSubmit} />
                                        </div>
                                    </div>

                                    <div className='row pt-4 mt-1'>
                                        <div className='col-6 pe-2'>
                                            <SecondaryButton name="Cancel"
                                                className="w-100"
                                                onPress={_handleCloseModal} />
                                        </div>
                                        <div className='col-6 ps-2'>
                                            <PrimaryButton name="Submit"
                                                status={createLoader}
                                                className="w-100"
                                                onPress={_handleSubmit} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="modal-header px-4 pt-4 pb-2 border-0 align-items-start">
                                <img src={require("../../Assets/Image/CountryAdmin/success-icon.svg").default}
                                    draggable={false}
                                    alt='icon' />
                                <Icon icon="close-icon"
                                    className='cursor-pointer'
                                    size="24px"
                                    onPress={_handleSuccess} />
                                <button type="button" className="btn-close shadow-none d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body px-4 pb-4 pt-2">
                                <h6 className='mb-2 e-primary-font-playfair e-font-18 e-text-raisin-black e-font-weight-500 line-height-28px'>
                                    Reward added successfully
                                </h6>
                                <p className='mb-0 e-text-davys-gray e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                    Successfully added the application.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                                </p>
                                <div className='pt-4 mt-2'>
                                    <PrimaryButton name="Ok"
                                        className="w-100"
                                        onPress={_handleSuccess} />
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default memo(AddRewardsModal);