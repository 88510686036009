/*
 *   File : approve-redemption.js
 *   Author : https://evoqins.com
 *   Description : Offer listing page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';

// Custom components
import { PrimaryButton } from '../Buttons';
import { Icon } from '../Icon';

const ManageRedemptionModal = (props) => {

    // handle success
    const _handleSuccess = () => {
        var button = document.getElementById("approve-redemption-modal-close");
        button.click();
        props.success();
    };

    return (
        <div className="modal fade" id="approve-redemption-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="approveRedemptionModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content e-border-radius-12 w-480px mx-auto ">
                    <img src={require("../../Assets/Image/CountryAdmin/close-icon.svg").default}
                        draggable={false}
                        className='d-none'
                        id='approve-redemption-modal-close'
                        alt='close'
                        data-bs-dismiss="modal" />
                    <div className="modal-header px-4 pt-4 pb-3 border-0 align-items-start">
                        <img src={require("../../Assets/Image/CountryAdmin/success-icon.svg").default}
                            draggable={false}
                            alt='delete' />
                        <Icon icon="close-icon"
                            className='cursor-pointer'
                            size="24px"
                            onPress={_handleSuccess} />

                    </div>
                    <div className="modal-body px-4 pt-1 pb-4">
                        <h4 className='mb-2 e-primary-font-playfair e-text-raisin-black e-font-18 e-font-weight-500 line-height-28px'>
                            {props.type === 1 ? "Rewards redeemed" : "Request accepted"}
                        </h4>
                        <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                            {props.type === 1 ?
                                "Successfully redeemed the rewards points.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis."
                                :
                                `${props.description}`
                            }
                        </p>

                        {/* Custom button  */}
                        <div className='pt-4 mt-2'>
                            <PrimaryButton name="Ok"
                                className="w-100"
                                onPress={_handleSuccess} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(ManageRedemptionModal);